(function ($) {
  Drupal.behaviors.splitWidthContentV1 = {
    attach: function (context) {
      var $template = $('.js-split-width-content-block', context);
      var $contentTextWrapper = $('.content-block__content-text', $template);

      $contentTextWrapper.each(function () {
        if (!$(this).text().trim().length) {
          $(this).addClass('empty-container');
        }
      });
    }
  };
})(jQuery);
